import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {

  var age = new Date(new Date() - (new Date("08.16.1988").getTime())).getUTCFullYear()-1970;
  // var age = NaN;
  age = isNaN(age)?35:age; //temporary Safary fix

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            I am <span className="purple">Alexander Fendel </span>
            from <span className="purple"> Hannover, Germany.</span>
            <br />
            A {age} year old Family Dad who is currently working as a Freelancer.
            <br />
            <br />
            I gained my Degree in B.Sc "Industrial Engineering Field Mechanical Engineering"
            at the Technical University of Braunschweig while mostly Programming for Volkswagen-Hannover fulltime.
            <br />
            <br />
            <br />
            Apart from coding, some other activities that I love to do:
          </p>
          <ul style={{paddingLeft: "10px"}}>
            <li className="about-activity">
              <ImPointRight /> Spend Time with Family & Friends
            </li>
            <li className="about-activity">
              <ImPointRight /> Traveling
            </li>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
          </ul>

          <p style={{ color: "#868fdb" }}>
           Motto: "Striving to be better then i have been yesterday."{" "}
          </p>
          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
