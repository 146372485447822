import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
// import leaf from "../../Assets/Projects/leaf.png";
// import emotion from "../../Assets/Projects/emotion.png";
// import editor from "../../Assets/Projects/codeEditor.png";
// import chatify from "../../Assets/Projects/chatify.png";
// import suicide from "../../Assets/Projects/suicide.png";
// import bitsOfCode from "../../Assets/Projects/blog.png";
import kid from "../../Assets/Projects/kid.jpg";
import fendeleu from "../../Assets/Projects/fendeleu.png";
import nido from "../../Assets/Projects/Nido.png";
import aig from "../../Assets/Projects/microsrvaig.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={fendeleu}
              isBlog={false}
              title="Introduction Hompage"
              description="This Website is handcrafted by me with pure React. Harnissing its rich libraries and fast development capabilities."
              // ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://www.fendel.eu"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={aig}
              isBlog={false}
              title="Large Longterm Archiving System"
              description="Developer in a 6 to 8-member Scrum team of an insurance group. A transnational data management system was developed for the automatic processing and long-term archiving of documents, e-mails and images. A user interface allows manual, automatic and post-processing as a role-specific status view, e-mail service, order placement and performance overview. Of the 50 or so microservices on which the system runs, I built, edited or maintained most of them. The following technologies, among others, were used for implementation: Angular, CSS, NodeJs, MongoDb, gRPC, Expressjs, OCR, ElasticSearch, Jenkins, Gitlab, Mocha, Chai, Cronjs, GraphQl, Postman, MachineLearning, Puppeteer, Java/Maven"
              // ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={nido}
              isBlog={false}
              title="Interpreter Mediation Application"
              description="Project coordinator for a project tendered by the state of Lower Saxony and carried out by the Ethno-Medical Center.
In addition to organizing and leading a nine-person team to develop a 60-hour training course, I also developed and implemented a web application for arranging interpreting assignments. Approximately 1000 interpreting assignments per month are arranged with this application, which I designed and implemented. Following Technologies and Frameworks were used for the realization: Angular, Nodejs, Postgresql, Mongodb, Html, Css, Java- and Typescript, Bootstrap, ExpressJs, Python, Deployment on Microsoft Azure"
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              // demoLink="https://editor.soumya-jit.tech/"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={kid}
              isBlog={false}
              title="Invest Databases at Volkswagen"
              description="Developed an Application for Volkswagen-Hannover to manage the investment Data of the procurement department. The application is used by the procurement and controlling department to manage capacaties to cover short-term changes of demand. The Application has been rolled out group-wide and is used by several Volkswagen plants nowadays."
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              // demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Ai For Social Good"
              description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
              ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
